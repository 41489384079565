import {
  AxonItem,
  GAItem,
  GoogleAnalyticsMainItem,
} from 'types/analyticsTypes';
import { Product, Subscription } from 'types/product';
import { getLocalisedProduct } from 'utils/localization';

export const convertKiloProductToGAItemMain = (
  product: any,
): GoogleAnalyticsMainItem => {
  const finalProduct = getLocalisedProduct(product);

  const { sku, name, finalPrice, currencyId, hasTrial, discountedTrialPrice } =
    finalProduct as Subscription;

  const category = 'Subscription';

  return {
    sku,
    name,
    category,
    price: String(hasTrial ? discountedTrialPrice || '0' : finalPrice || '0'),
    quantity: 1,
    currency: currencyId,
  };
};

export const convertKiloProductToGAItem = (
  product: any,
  purchase_type: string,
  coupon?: any,
  quantity?: any,
): GAItem => {
  const finalProduct = getLocalisedProduct(product);
  const {
    name,
    finalPrice,
    sku,
    key,
    currency,
    discount,
    regularPrice,
    hasTrial,
    discountedTrialPrice,
  } = finalProduct as Subscription;

  return {
    key,
    item_id: sku,
    item_name: name,
    affiliation: 'Carnimeat',
    purchase_type,
    coupon,
    currency,
    discount: Number(discount),
    price: Number(
      Number(
        hasTrial ? discountedTrialPrice || '0' : finalPrice || '0',
      ).toFixed(2) || 0,
    ),
    original_price: Number(Number(regularPrice).toFixed(2) || 0),
    quantity: quantity || 1,
    type: product.type,
  };
};

export const convertKiloProductAppLovin = (product: any): AxonItem => {
  const finalProduct = getLocalisedProduct(product);

  const { sku, name, finalPrice, currencyId, hasTrial, discountedTrialPrice } =
    finalProduct as Subscription;

  return {
    item_id: sku,
    item_name: name,
    price: hasTrial ? Number(discountedTrialPrice) || 0 : Number(finalPrice),
    quantity: 1,
    item_category_id: 2092,
  };
};
