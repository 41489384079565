import './assets/fonts/fonts.css';
import './components/payments/Payments';
import React, { FC, StrictMode } from 'react';
import { Provider, useSelector } from 'react-redux';
import { configStore } from 'state/store';
import { BlockLocationWrapper, BodyWrapper, withApp } from 'components';
import { Footers, Modals, Navigations } from 'layouts';
import { PersistGate } from 'redux-persist/integration/react';
import styled, { ThemeProvider } from 'styled-components';
import theme from './utils/theme';
import { InitialLoader } from './components';
import { AnalyticsProvider } from 'components/providers/AnalyticsProvider';
import { AppState } from 'state/types';

const { store, persistor } = configStore();

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

interface LandingWrapperProps {
  element: { element: any };
  showAnalyticsProvider: boolean;
}

const LandingWrapper: FC<LandingWrapperProps> = ({
  element,
  showAnalyticsProvider,
}) => {
  const isLoading = useSelector(
    (state: AppState) =>
      !(state.funnel && state.config && state.user && state.payment),
  );
  const enhancedElement = (
    <BlockLocationWrapper>{withApp(element)}</BlockLocationWrapper>
  );

  return (
    <BodyWrapper>
      {isLoading ? (
        <InitialLoader />
      ) : (
        <Container>
          <Navigations />
          {showAnalyticsProvider ? (
            <AnalyticsProvider>{enhancedElement}</AnalyticsProvider>
          ) : (
            <>{enhancedElement}</>
          )}
          <Modals />
        </Container>
      )}
    </BodyWrapper>
  );
};

const wrapRootElement = ({ element }: { element: any }) => {
  if (typeof window === 'undefined') {
    return (
      <StrictMode>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <PersistGate loading={null} persistor={persistor}>
              <LandingWrapper element={element} showAnalyticsProvider={false} />
            </PersistGate>
          </ThemeProvider>
        </Provider>
      </StrictMode>
    );
  }
  return (
    <StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <PersistGate loading={null} persistor={persistor}>
            <LandingWrapper element={element} showAnalyticsProvider={true} />
          </PersistGate>
        </ThemeProvider>
      </Provider>
    </StrictMode>
  );
};

export { store, wrapRootElement };
